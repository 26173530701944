.rsvp-options {
    margin-top: 2rem;
    
    .btn {
        font-size: 1.5rem;
    }
}

.rsvp-option-buttons {
    width: 300px;
    max-width: 100%;
    margin: auto;
}

.brunch {
    label {
        display: inline-block;
        margin: 0;
        margin-right: 21px;

        input {
            margin: 0;
        }
        
        &:last-child {
            margin-right: 0;
        }
    }
}

h2 {
    small {
        font-size: 0.8rem;
        line-height: 1.2rem;
        font-weight: normal;
    }
}
