@import '../variables.scss';

.picture {
    padding: 20px;
    text-align: center;
}

label {
    font-size: 1.5rem;
    margin-bottom: 0.7rem;
    display: block;
}

#rsvp {
    margin-top: 3rem;
}

input {
    font-size: 2rem;
    font-family: inherit;
    text-align: center;

    &[type=text] {
        background: transparent;
        border: 0;
        color: $inv-dark-gray;
        border-bottom: 2px $inv-dark-gray solid;
        max-width: 100%;
        width: 100%;
    
        border-radius: 3px 3px 0 0;    

        &::placeholder {
            color: $inv-dark-gray-transparent;
            font-style: italic;
        }

        &:focus {
            outline: none;
            background: rgba(255, 255, 255, 0.2);
        }
    }
}

.submit-row {
    margin-top: 3rem;
}

img {
    max-width: 100%;
}
