body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  margin: 0;
  padding: 0; }

body {
  background: transparent;
  background-size: cover;
  color: black;
  overflow: auto;
  font: 16px Helvetica,Arial, sans-serif; }

h1, h2, h3, h4, h5, h6 {
  color: #222; }

.App {
  max-width: 770px;
  margin: auto; }

a {
  color: #222; }

hr {
  border: 1px #222 solid; }

.btn {
  font-family: inherit;
  background: #222;
  color: white;
  cursor: pointer;
  border-radius: 3px;
  border: 0;
  padding: 0.4rem 1.5rem; }
  .btn[disabled] {
    background: #3c3c3c;
    cursor: none; }
    .btn[disabled]:hover {
      background: #3c3c3c; }
  .btn:hover {
    background: #090909; }
  .btn.btn-toggle {
    background: #3c3c3c; }
    .btn.btn-toggle:hover {
      background: #090909; }
    .btn.btn-toggle.btn-selected {
      background: #222; }

.p {
  margin: 1rem 0; }

.picture {
  padding: 20px;
  text-align: center; }

label {
  font-size: 1.5rem;
  margin-bottom: 0.7rem;
  display: block; }

#rsvp {
  margin-top: 3rem; }

input {
  font-size: 2rem;
  font-family: inherit;
  text-align: center; }
  input[type=text] {
    background: transparent;
    border: 0;
    color: #222;
    border-bottom: 2px #222 solid;
    max-width: 100%;
    width: 100%;
    border-radius: 3px 3px 0 0; }
    input[type=text]::-webkit-input-placeholder {
      color: #22222281;
      font-style: italic; }
    input[type=text]::-ms-input-placeholder {
      color: #22222281;
      font-style: italic; }
    input[type=text]::placeholder {
      color: #22222281;
      font-style: italic; }
    input[type=text]:focus {
      outline: none;
      background: rgba(255, 255, 255, 0.2); }

.submit-row {
  margin-top: 3rem; }

img {
  max-width: 100%; }

.rsvp-options {
  margin-top: 2rem; }
  .rsvp-options .btn {
    font-size: 1.5rem; }

.rsvp-option-buttons {
  width: 300px;
  max-width: 100%;
  margin: auto; }

.brunch label {
  display: inline-block;
  margin: 0;
  margin-right: 21px; }
  .brunch label input {
    margin: 0; }
  .brunch label:last-child {
    margin-right: 0; }

h2 small {
  font-size: 0.8rem;
  line-height: 1.2rem;
  font-weight: normal; }

.meal-option {
  display: block; }

.person-entry .input-container {
  margin-bottom: 14px; }

.person-entry .tag {
  font-size: 0.7rem;
  text-transform: uppercase;
  background: #22222281;
  border-radius: 3px;
  padding: 3px 4px;
  color: white;
  margin-right: 4px;
  display: inline-block;
  margin-top: 7px; }

.person-entry input[type=text] {
  width: 50%;
  min-width: 200px;
  display: block;
  margin: auto;
  box-sizing: border-box;
  font-size: 1.2rem;
  border: 1px #22222281 solid;
  border-radius: 3px;
  padding: 3px;
  font-style: normal;
  background: rgba(255, 255, 255, 0.2); }
  .person-entry input[type=text]::-webkit-input-placeholder {
    font-style: normal; }
  .person-entry input[type=text]::-ms-input-placeholder {
    font-style: normal; }
  .person-entry input[type=text]::placeholder {
    font-style: normal; }
  .person-entry input[type=text]:focus {
    outline: none;
    background: rgba(255, 255, 255, 0.5); }

.person-entry input[type=radio] {
  display: block; }

.person-entry .checkbox {
  float: left;
  clear: both;
  width: 30px;
  padding-top: 0px; }

.person-entry label {
  font-size: 1rem;
  text-align: left;
  margin-left: 30px; }

.person-entry ul {
  clear: both;
  list-style: none;
  margin: 0;
  padding: 0; }
  .person-entry ul li {
    list-style: none;
    margin-top: 21px;
    padding: 0; }

.lds-heart {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transform-origin: 32px 32px;
          transform-origin: 32px 32px; }

.lds-heart div {
  top: 23px;
  left: 19px;
  position: absolute;
  width: 26px;
  height: 26px;
  background: #222;
  -webkit-animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
          animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1); }

.lds-heart div:after,
.lds-heart div:before {
  content: " ";
  position: absolute;
  display: block;
  width: 26px;
  height: 26px;
  background: #222; }

.lds-heart div:before {
  left: -17px;
  border-radius: 50% 0 0 50%; }

.lds-heart div:after {
  top: -17px;
  border-radius: 50% 50% 0 0; }

@-webkit-keyframes lds-heart {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95); }
  5% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  39% {
    -webkit-transform: scale(0.85);
            transform: scale(0.85); }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  60% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95); }
  100% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); } }

@keyframes lds-heart {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95); }
  5% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  39% {
    -webkit-transform: scale(0.85);
            transform: scale(0.85); }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  60% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95); }
  100% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); } }

