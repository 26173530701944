@import 'variables.scss';

html, body {
  margin: 0;
  padding: 0;
}

body {
  background: transparent;
  background-size: cover;
  color: black; // $inv-dark-gray;
  overflow: auto;
  font: 16px Helvetica,Arial, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  color: $inv-dark-gray;
}

.App {
  max-width: 770px;
  margin: auto;
}

a {
  color: $inv-dark-gray;
}

hr {
  border: 1px $inv-dark-gray solid;
}

.btn {
  font-family: inherit;
  
  background: $inv-dark-gray;
  color: $inv-light-brown;
  cursor: pointer;

  border-radius: 3px;
  border: 0;
  padding: 0.4rem 1.5rem;

  &[disabled] {
    background: lighten($inv-dark-gray, 10%);
    cursor: none;

    &:hover {
      background: lighten($inv-dark-gray, 10%);
    }
  }

  &:hover {
    background: darken($inv-dark-gray, 10%);
  }
  
  &.btn-toggle {
    background: lighten($inv-dark-gray, 10%);

    &:hover {
      background: darken($inv-dark-gray, 10%);
    }

    &.btn-selected {
      background: $inv-dark-gray;
    }
  }
}

.p {
  margin: 1rem 0;
}
