@import '../variables.scss';

.meal-option {
    display: block;
}

.person-entry {
    .input-container {
        margin-bottom: 14px;
    }

    .tag {
        font-size: 0.7rem;
        text-transform: uppercase;
        background: $inv-dark-gray-transparent;
        border-radius: 3px;
        padding: 3px 4px;
        color: $inv-light-brown;
        margin-right: 4px;
        display: inline-block;
        margin-top: 7px;
    }

    input[type=text] {
        width: 50%;
        min-width: 200px;
        display: block;
        margin: auto;
        box-sizing: border-box;
        font-size: 1.2rem;
        border: 1px $inv-dark-gray-transparent solid;
        border-radius: 3px;
        padding: 3px;
        font-style: normal;
        background: rgba(255, 255, 255, 0.2);

        &::placeholder {
            font-style: normal;
        }

        &:focus {
            outline: none;
            background: rgba(255, 255, 255, 0.5);
        }
    }

    input[type=radio] {
        display: block;
    }

    .checkbox {
        float: left;
        clear: both;
        width: 30px;
        padding-top: 0px;
    }

    label {
        font-size: 1rem;
        text-align: left;
        margin-left: 30px;
    }

    ul {
        clear: both;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            list-style: none;
            margin-top: 21px;
            padding: 0;
        }
    }
}
